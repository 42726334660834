/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { I18NCommonService, WindowService } from '@dpa/ui-common';
import { EffectsModule } from '@ngrx/effects';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { I18NToCommonService } from '@ws1c/intelligence-common/services/i18nToCommon.service';
import { IntelligenceCoreComponentsModule } from '@ws1c/intelligence-core';
import { featureEffectsRun } from './effects';
import { MERLOT_PROVIDERS } from './services';
import { MERLOT_ACTION_PROVIDERS } from './store';

declare var window; // eslint-disable-line no-var

/**
 * win
 * @export
 * @returns {any}
 */
export function win() {
  return window;
}

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 * @export
 * @class MerlotModule
 */
@NgModule({
  imports: [IntelligenceCommonModule, IntelligenceCoreComponentsModule, LayoutModule, EffectsModule.forFeature(featureEffectsRun)],
  providers: [
    MERLOT_PROVIDERS,
    MERLOT_ACTION_PROVIDERS,
    { provide: I18NCommonService, useClass: I18NToCommonService },
    { provide: WindowService, useFactory: win },
  ],
  exports: [IntelligenceCommonModule, IntelligenceCoreComponentsModule],
})
export class MerlotModule {}
